<template>
  <div class="team">
    <v-container class="indigo lighten-5 my-5">
      <v-card class="white pa-3">
        <h1 class="text-center mb-3 subtitle-4 black--text"> جدول الامتحانات </h1>
        <h3 class="text-center mb-3 subtitle-4 black--text"> {{ $route.params.class_school_leader }}-{{
            $route.params.classes_name
        }} </h3>
        <!-- <div>
          <h5 class="text-center mb-3 subtitle-4 blue--text">
            * ملاحظة يمكن اضافة الدرجات عن طريق الضغط على <span class="glow">اسم الامتحان</span>
          </h5>
        </div> -->
        <v-row>
          <v-col md="3" sm="3" xs="12" align-self="center">
            <v-btn tile color="primary" @click="addDailog.open = true"> وصف امتحان (شهري - فصلي) <v-icon right> fa-plus
              </v-icon>
            </v-btn>
          </v-col>
          <v-spacer></v-spacer>
          <v-col md="3" sm="3" xs="12">
            <v-text-field v-model="table.search" label="البحث" append-icon="mdi-magnify" single-line hide-details>
            </v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-data-table :loading="table.loading" loading-text="جاري التحميل ... الرجاء الانتظار" :headers="headers"
              :items="studentsData" :search="table.search" :items-per-page="10" item-key="account_id"
              class="elevation-1" :footer-props="{
                showFirstLastPage: true,
              }">
              <template slot="item.exams_id" slot-scope="props"> {{ props.index + 1 }} </template>
              <template v-slot:item.exams_name="{ item }">
                <router-link
                  :to="'/exams/examSubject/' + $route.params.class_school_id + '/' + item._id + '/' + item.exams_name">
                  {{ item.exams_name }} </router-link>
              </template>
              <template v-slot:item.actions="{ item }">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon color="primary" class="ml-2" v-bind="attrs" size="18" v-on="on" @click="editItem(item)">
                      fa-edit </v-icon>
                  </template>
                  <span>تعديل</span>
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon color="#FF8A80" class="ml-2" v-bind="attrs" size="18" v-on="on" @click="deleteItem(item)">
                      fa-trash </v-icon>
                  </template>
                  <span>حذف</span>
                </v-tooltip>
                <v-btn color="primary" class="ml-2" small
                  @click="$router.push('/exams/examSubject/' + $route.params.class_school_id + '/' + item._id + '/' + item.exams_name)">
                  اضافة جدول </v-btn>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-card>
    </v-container>
    <!-- delete dialog -->
    <v-dialog v-model="dialogDelete" max-width="500px">
      <v-card>
        <v-card-title class="headline justify-center"> هل انت متأكد من حذف هذا الامتحان ؟ </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="dialogDelete = false"> الغاء </v-btn>
          <v-btn color="primary" :loading="deleteItemLoading" @click="deleteItemConfirm"> حذف </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- End delete dailog -->
    <!-- add dialog -->
    <v-dialog v-model="addDailog.open" max-width="500px">
      <v-card>
        <v-card-title>
          <span class="headline"> وصف امتحان (شهري - فصلي)</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-form v-model="addDailog.isFormValid">
              <v-row>
                <v-col cols="12">
                  <v-text-field v-model="exam_data.exams_name" label="وصف الامتحان" :rules="Rules.exams_name" outlined
                    required @keyup.enter="submitAdd"></v-text-field>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions class="ml-3">
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="addDailog.open = false"> الغاء </v-btn>
          <v-btn color="primary" :loading="addDailog.loading" :disabled="!addDailog.isFormValid" @click="submitAdd">
            اضافة </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- add dialog -->
    <!-- edit dialog -->
    <v-dialog v-model="editDailog.open" max-width="500px">
      <v-card>
        <v-card-title>
          <span class="headline">تعديل الامتحان</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-form v-model="editDailog.isFormValid">
              <v-row>
                <v-col cols="12">
                  <v-text-field v-model="editedItem.exams_name" label="وصف الامتحان" :rules="editRules.exams_name"
                    outlined required @keyup.enter="submitEdit"></v-text-field>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions class="ml-3">
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="editDailog.open = false"> الغاء </v-btn>
          <v-btn color="primary" :loading="editDailog.loading" :disabled="!editDailog.isFormValid" @click="submitEdit">
            تعديل </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- edit dialog -->
    <!--- Dailog for show info to user-->
    <v-dialog v-model="dialogData.open" max-width="500px">
      <v-toolbar :color="dialogData.color" dense></v-toolbar>
      <v-card>
        <v-card-title class="headline justify-center"> {{ dialogData.bodyText }} </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="dialogData.open = false"> تم </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import api from '@/api/api'
export default {
  data() {
    return {
      deleteItemLoading: false,

      dialogDelete: false,

      editedItem: {},

      addDailog: {
        open: false,
        loading: false,
        isFormValid: false,
      },

      oldExamName: null,

      editDailog: {
        open: false,
        loading: false,
        isFormValid: false,
      },

      exam_data: { exams_name: null },

      Rules: {
        exams_name: [value => !!value || 'الوصف مطلوب'],
      },

      editRules: {
        exams_name: [value => !!value || 'الوصف مطلوب'],
      },

      dialogData: {
        open: false,
        color: 'primary',
        bodyText: 'test',
      },

      deletedItem: {},

      table: {
        loading: false,
        search: null,
        imageUrlForShow: null,
        showImageDailog: false,
      },
      headers: [
        {
          text: '#',
          align: 'start',
          width: 1,

          value: 'exams_id',
        },
        {
          text: 'وصف الامتحان',
          value: 'exams_name',
        },
        { text: 'العمليات', value: 'actions', sortable: false },
      ],
      studentsData: [],
    }
  },
  mounted() {
    this.getStudentData()
  },

  methods: {
    async getStudentData() {
      this.table.loading = true

      const study_year = JSON.parse(localStorage.getItem('study_year'))

      const response = await api.getOneExams(this.$route.params.class_school_id, study_year)
      if (response.status === 401) {
        this.table.loading = false
        this.$store.dispatch('submitLogout')
      } else if (response.status === 500) {
        this.table.loading = false
        this.showDialogfunction(response.data.results, '#FF5252')
      } else {
        this.table.loading = false
        this.studentsData = response.data.results
        // this.leader_select = response.data.results
      }
    },

    deleteItem(item) {
      this.deletedItem = { ...item }
      this.dialogDelete = true
    },

    async deleteItemConfirm() {
      this.deleteItemLoading = true

      const response = await api.removeExams(this.deletedItem._id)

      if (response.status === 401) {
        this.deleteItemLoading = false
        this.dialogDelete = false
        this.$store.dispatch('submitLogout')
      } else if (response.status === 500) {
        this.deleteItemLoading = false
        this.dialogDelete = false
        this.showDialogfunction(response.data.results, '#FF5252')
      } else {
        this.deleteItemLoading = false
        this.dialogDelete = false
        this.getStudentData()
        this.showDialogfunction(response.data.results, 'primary')
      }
    },

    async submitAdd() {
      this.addDailog.loading = true

      const study_year = JSON.parse(localStorage.getItem('study_year'))

      const response = await api.addOneExams({ exams_name: this.exam_data.exams_name, exams_study_year: study_year, exams_for_class_school: this.$route.params.class_school_id })

      if (response.status === 401) {
        this.addDailog.loading = false
        this.$store.dispatch('submitLogout')
      } else if (response.status === 500) {
        this.addDailog.loading = false
        this.addDailog.open = false
        this.showDialogfunction(response.data.results, '#FF5252')
      } else {
        this.addDailog.loading = false
        this.addDailog.open = false
        this.getStudentData()
        this.showDialogfunction(response.data.results, 'primary')
      }
    },

    editItem(item) {
      this.editedItem = { ...item }
      this.oldExamName = this.editedItem.exams_name
      this.editDailog.open = true
    },

    async submitEdit() {
      this.editDailog.loading = true

      const response = await api.editExams(this.editedItem.exams_name, this.editedItem._id)

      if (response.status === 401) {
        this.editDailog.loading = false
        this.editDailog.open = false
        this.$store.dispatch('submitLogout')
      } else if (response.status === 500) {
        this.editDailog.loading = false
        this.editDailog.open = false
        this.showDialogfunction(response.data.results, '#FF5252')
      } else {
        this.editDailog.loading = false
        this.editDailog.open = false
        this.getStudentData()
        this.showDialogfunction(response.data.results, 'primary')
      }

    },

    showImage(image) {
      this.table.showImageDailog = true
      this.table.imageUrlForShow = image
    },

    showDialogfunction(bodyText, color) {
      this.dialogData.open = true
      this.dialogData.bodyText = bodyText
      this.dialogData.color = color
    },
  },
}
</script>

<style>
.teacher_image_table {
  cursor: pointer;
}
</style>

<style scoped>
a {
  text-decoration: none;
}

.glow {
  animation: glow 1s ease-in-out infinite alternate;
}

@keyframes glow {
  0% {}

  50% {
    text-shadow: 0 0 10px #fff, 0 0 20px #fff, 0 0 30px #e60073, 0 0 40px #e60073, 0 0 50px #e60073, 0 0 60px #e60073,
      0 0 70px #e60073;
  }

  100% {
    text-shadow: 0 0 20px #fff, 0 0 30px #ff4da6, 0 0 40px #ff4da6, 0 0 50px #ff4da6, 0 0 60px #ff4da6, 0 0 70px #ff4da6,
      0 0 80px #ff4da6;
  }
}
</style>
